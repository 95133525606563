import { FormControl, RadioGroup, FormControlLabel, Input, Typography, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReduxStateCompanyItem } from '@reducers/companies/reducer'
import React, {useEffect, useState} from 'react'
import { useDispatch } from 'react-redux'
import { ReduxDispatch } from '../../../../typings/ReduxDispatch'
import StyledRadio from '../../../elements/StyledRadio'
import PageSection from '../../../layout/PageSection'
import CompanyDispatcher from '../../../reducers/companies/dispatcher'
import CompanyPreference from './components/CompanyPreference'
import ConfigLabel from './components/ConfigLabel'
import CompanyConfigLocations from './config/CompanyConfigLocations'

const useStyles = makeStyles(
  {
    contentContainer: {
      padding: 20,
      display: 'flex',
      flexDirection: 'row',
    },
    employeeAppConfig: {
      paddingLeft: 20,
      paddingRight: 20,
      display: 'flex',
      flexDirection: 'column',
    },
    colorItem: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
    },
    caption: {
      fontWeight: 800,
      fontSize: 18,
      marginBottom: 20,
    },
    configInput: {
      height: 45,
      borderRadius: 6,
      border: '1px solid #D4D4D4',
      padding: '0 20px',
      marginBottom: 5,
      '&::before': {
        borderBottom: 0,
      },
    },
  },
  {
    classNamePrefix: 'CompanyConfig',
  },
)

interface Props {
  company: ReduxStateCompanyItem
}

interface PropsInner extends Props {}

function CompanyConfig(props: PropsInner) {
  const { company } = props
  const dispatch = useDispatch<ReduxDispatch>()
  const classes = useStyles()
  const { config } = company
  let {
    live_session_type = 'meeting',
    is_public_events_enabled,
    is_allow_export_data_enabled = true,
    locations,
    employee_app_config,
  } = config || {}

  const [configState, setConfig] = useState(employee_app_config)

  useEffect(() => {
    setConfig(employee_app_config)
  }, [employee_app_config])

  // locations = locations.asMutable()
  // locations.push('UA')
  // locations.push('US')
  // locations.push('WW')
  if (!config) return null

  const handleEventTypeChange = async (event, type: any) => {
    await dispatch(CompanyDispatcher.setConfigLiveEventType(company.id, { type }))
    // await dispatch(CompanyDispatcher.setPreferenceCoffeeCorner(company.id, payload))
  }

  const handlePublicEventToggle = async () => {
    await dispatch(
      CompanyDispatcher.setConfigPublicEventsEnabled(company.id, { is_enabled: !is_public_events_enabled }),
    )
  }

  const handleExportDataToggle = async () => {
    await dispatch(
      CompanyDispatcher.setAllowDataExportEnabled(company.id, { is_enabled: !is_allow_export_data_enabled }),
    )
  }

  const handleSaveConfig = async () => {
    await dispatch(CompanyDispatcher.setEmployeeAppConfig(company.id, configState))
  }

  const handleLunchIcon = (value) => {
    setConfig({ ...configState, lunch_icon_name: value })
  }
  const handleChangeColor = (value) => {
    setConfig({ ...configState, color: value })
  }

  const handleChangeBGColor = (value) => {
    setConfig({ ...configState, background_color: value })
  }

  return (
    <PageSection title={'Configs'}>
      <div className={classes.contentContainer}>
        <div>
          <CompanyPreference
            title={'Public events:'}
            isTrue={!!is_public_events_enabled}
            onChange={handlePublicEventToggle}
          />
          <CompanyPreference
            title={'Allow export data:'}
            isTrue={!!is_allow_export_data_enabled}
            onChange={handleExportDataToggle}
          />
          <FormControl component="fieldset">
            <ConfigLabel title={'Zoom Live Event Type:'} />
            <RadioGroup
              value={live_session_type}
              defaultValue="meeting"
              aria-label="live events"
              name="customized-radios"
              onChange={handleEventTypeChange}
            >
              <FormControlLabel value="meeting" control={<StyledRadio />} label="Meeting" />
              <FormControlLabel value="webinar" control={<StyledRadio />} label="Webinar" />
            </RadioGroup>
          </FormControl>

          <CompanyConfigLocations company={company} />
        </div>
        <div className={classes.employeeAppConfig}>
          <ConfigLabel title={'Employee app config'} />
          <FormControl>
            <Typography className={classes.caption}>Lunch icon name</Typography>
            <Input
              value={configState?.lunch_icon_name}
              onChange={(e) => handleLunchIcon(e.target.value)}
              placeholder={'Lunch icon name'}
              className={classes.configInput}
              disableUnderline
            />
          </FormControl>
          <FormControl>
            <Typography className={classes.caption}>Main color</Typography>
            <div className={classes.colorItem}>
              <Input
                value={configState?.color}
                onChange={(e) => handleChangeColor(e.target.value)}
                placeholder={'Main color'}
                className={classes.configInput}
                disableUnderline
              />
              <div
                style={{ backgroundColor: configState?.color, width: 35, height: 35, marginLeft: 5, borderRadius: 6 }}
              />
            </div>
          </FormControl>
          <FormControl>
            <Typography className={classes.caption}>Background color</Typography>
            <div className={classes.colorItem}>
              <Input
                value={configState?.background_color}
                onChange={(e) => handleChangeBGColor(e.target.value)}
                placeholder={'Main color'}
                className={classes.configInput}
                disableUnderline
              />
              <div
                style={{
                  backgroundColor: configState?.background_color,
                  width: 35,
                  height: 35,
                  marginLeft: 5,
                  borderRadius: 6,
                }}
              />
            </div>
            {(configState?.lunch_icon_name != employee_app_config?.lunch_icon_name ||
              configState?.background_color != employee_app_config?.background_color ||
              configState?.color != employee_app_config?.color) && (
              <Button onClick={() => handleSaveConfig()}>Save</Button>
            )}
          </FormControl>
        </div>
      </div>
    </PageSection>
  )
}

export default CompanyConfig
