import { makeStyles } from '@mui/styles'
import React from 'react'
import clearIcon from './modals/assets/close.svg'

const useStyles = makeStyles(
  {
    clear: {
      top: 20,
      right: 20,
      position: 'absolute',
      cursor: 'pointer',
    },
  },
  {
    classNamePrefix: 'CloseButton',
  },
)

interface Props {
  position?: 'static' | 'absolute'
  onPress(): any
}

function CloseButton(props: Props) {
  const { onPress, position = 'absolute' } = props
  const classes = useStyles()

  const style = {
    position,
  }

  return (
    <div>
      <img src={clearIcon} className={classes.clear} style={style} onClick={onPress} alt="delete" />
    </div>
  )
}

export default CloseButton
