// import l from '@reaction-localization/mobile'
// import companyErrorCodes from '../../../apps/employee/pages/joinCompany/const/errorCodes'
import authErrorCodes from '../const/authErrorCodes'
import errorCodes from '../const/errorCodes'
import eventErrorCodes from '../const/eventErrorCodes'

export function getMessageByErrorCode(errorCode: string): string {
  return errorCode
}

export default getMessageByErrorCode
