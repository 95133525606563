import keyManager from 'constant-manager'

export default keyManager(
  {
    ADD_SUCCESS: '',
    ADD_ERROR: '',
    ADD_WARNING: '',
    MAKE_EMPTY: '',
  },
  { prefix: 'Notification__' },
)
